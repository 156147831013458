export const environment = {
  production: true,

  firebase: {
    apiKey: "AIzaSyDPsAs7ZyNb_YBWpKC-IiVTp2twOsyLrU0",
    authDomain: "giants-foundation.firebaseapp.com",
    projectId: "giants-foundation",
    storageBucket: "giants-foundation.appspot.com",
    messagingSenderId: "394630238705",
    appId: "1:394630238705:web:43f0dabef4d9bda86bf450",
    measurementId: "G-6JG0ZKWJLM"
  },

  appVersioning: {
    appVersion: '1.0.6'
  }
};
