export class MeetingWorldCouncileOverview{
    ID:Number;
    GROUP_ID:number;
    TOTAL_MEETING:number;
    MEETING_PLANNED:number;
    MEETING_CONDUCTED:number;
    AVG_ATTENDENCE:number;
    MEETING_TIME:any;
    AVG_MEETING_PER_DAY:number;
    AVG_MEETING_PER_GROUP:number; 
    
    FEDERATION_COUNT:number;
    GROUP_COUNT:number;
    MEMBER_COUNT:number;
    UNIT_COUNT:number;

    NO_OF_SHARES:number;
    AVG_LIKE_PER_MEMBER:number;
    TOTAL_COMMENT_TILL_DATE:number;
    AVG_COMMENT_PER_MEMBER:number;
    AVG_POST_PER_MEMBER:number;
    TOTAL_LIKE_TILL_DATE:number;
    TOTAL_POST:number;

}