export class OustandingYoungGiantsDivisionMaster {
  ID: number;
  CLIENT_ID: number;
  GROUP_ID: number;
  DESCRIPTION_OF_COHESIVE_WELLKNIT: any;
  NO_OF_MEMBERS: number;
  NO_OF_WELL_KNIT: number;
  PARENT_GROUP_DESCRIPTION: any;
  ACTIVE_PARTICIPATION_MEMBERS: number;
  ACTIVE_PARTICIPATION_MEMBERS_PERCENTAGE: number;
  NO_OF_REGULAR_MEET: number;
  PERCENTAGE_OF_REGULAR_MEET: number;
  BOARD_MEETINGS: number;
  BOARD_PRESENT_DIR: number;
  NO_OF_PARENT_BODY_MEET: number;
  PERCENTAGE_OF_PARENT_BODY_MEET: number;
  NO_OF_YG: number;
  PERCENTAGE_OF_YG: number;
  DESCRIPTION_OF_PRESIDENT_MEET_PROJECT: any;
  DESCRIPTION_OF_NAME_FAME: any;
  BANK_NAME: string;
  BANK_ACCOUNT_NUMBER: any;
  DESCRIPTION_PROJECT_DETAILS: any;
  Swicthing1: boolean = false;
  Swicthing2: boolean = false;
  Swicthing3: boolean = false;
  IS_SUBMITED: string = 'D';
  GROUP_NAME: string;
}