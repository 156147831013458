export class MeetingsOfTheMonth {
    ID: number;
    MEETING_ID: number;
    MEETING_SUBJECT: string;
    REPORT_MASTER_ID: number;
    DATE: any;
    ATTENDANCE: number
    ATTENDANCE_OUT_OF: number;
    PHOTO_URL: any;
    IS_INVITION_SEND: boolean;
    IS_ATTENDANCE_MARKED: boolean;
    MEETING_MINUTES: string;
    PHOTO1: string;
}