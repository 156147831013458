export class ThePresident {
    ID: number;
    PERSON: string = '';
    GROUP_ID: number;
    MEMBER_ID: number;
    NO_OF_IN_MEETINGS: number;
    MEETINGS_OUT_OF: number;
    NO_OF_PROJECTS: number;
    PROJECTS_OUT_OF: number;
    NO_UNIT_CONFERENCES: number;
    UNIT_CONFERENCES_OUT_OF: number;
    PAST_CONVENTION: number;
    CONVENTION_DETAILS: string = '';
    MEMBER_PREVIOUS_YEAR: any;
    MEMBER_DURING_YEAR: any;
    MEMBER_DROPPED_DURING_YEAR: any;
    GROWTH_DETAILS: string = '';
    GROWTH_RETENTION: string = '';
    FINANCIAL_POSITION: string = '';
    MEETING_PLACE: string = '';
    BRANCH_CONVENTION: string = "";
    OTHER_DETAILS: string = '';
    MEETING_DATE: any;
    FILE_URL: any;
    OUTSTANDING_PRESIDENT_DETAILS: any[];
    CLIENT_ID: number = 1;
    SWICTHING: boolean = true;
    IS_SUBMITED: string = 'D';
}
