export class SystemFeesMaster {
    ID: number;
    NAME: any;
    CLIENT_ID: number;
    YEAR_ID: number;
    INT_EMAIL: number;
    FED_EMAIL: number;
    GR_EMAIL: number;
    INT_SMS: number;
    FED_SMS: number;
    GR_SMS: number;
    INT_WA: number;
    FED_WA: number;
    GR_WA: number;
    INT_FEE: number;
    FED_FEE: number;
    GR_FEE: number
}