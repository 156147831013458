export class ProjectsWorldCouncileOverview{
    ID:Number;
    GROUP_ID:number;
    ONGOING_PROJECTS:number;
    FUND_UTILISED:number;
    AVG_PROJECT_PER_GROUP:number;
    AVG_FUND_PER_PROJECT:number;
    TOTAL_BENEFICIARIES:number;
    TOTAL_PROJECTS:number;

    COMPLETED_PROJECTS:any;
   

}