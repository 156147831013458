export class ProjectsOfTheMonth {
    ID: number;
    PROJECT_ID: number
    REPORT_MASTER_ID: number;
    NAME_OF_PROJECT: string;
    DATE: any;
    EXPENSE_DURING_PROJECT: number
    NO_OF_BENEFICIARY: number;
    PHOTO_URL: any;
    PHOTO1: string;
}