export class MonthlyGroupReport {
    ID: number;
    CLIENT_ID: number;
    MEMBERSHIP_CLOSED_LAST_MONTH: number = 0;
    MEMBERSHIP_ADDED_DURING_MONTH: number = 0;
    MEMBERSHIP_DROPED_DURING_MONTH: number = 0;
    MEMBERSHIP_CLOSED_THIS_MONTH: number = 0;
    MEMSUB_JANTOJUNE: number;
    MEMSUB_JULYTODEC: number;
    NO_OF_SUB_MEMBER: number;
    PER_MEMBER_SUB_FEES: number;
    TOTAL_SUB_FEES: number;
    NO_OF_ENTERENCE_MEMBER: number;
    PER_MEMBER_ENTERENCE_FEES: number;
    TOTAL_ENTERENCE_FEES: number;
    TOTAL_AMOUNT: number;
    PAID_AMOUNT: number;
    CHEQUE_DD_NO: any;
    DRAWN_ON: any;
    REMITTED_ON: any;
    ADMIN_ACCOUNT_NO: any;
    ADMIN_BANK_NAME: any;
    ADMIN_BRANCH_NAME: any;
    PROJECT_ACCOUNT_NO: any;
    PROJECT_BANK_NAME: any;
    PROJECT_BRANCH_NAME: any;
    GROUPMEETING_HELD_ON: any;
    GROUPMEETING_ATTENDED_BY: any;
    GROUPMEETING_TOTAL_MEMBER: any;
    GROUPMEETING_ATTENDANCE_PERCENTAGE: number;
    BOARDMEETING_HELD_ON: any;
    BOARDMEETING_ATTENDED_BY: any;
    BOARDMEETING_TOTAL_MEMBER: any;
    BOARDMEETING_ATTENDANCE_PERCENTAGE: number;
    IS_SUBMITTED: string = 'D';
    GROUP_ID: number;
    FEDERATION_ID: number;
    UNIT_ID: number;
    MONTH: number;
    YEAR: number;
    PHOTO_URL1: any;
    PHOTO_URL2: any;
    PHOTO_URL3: any;
    PHOTO_URL4: any;
    PHOTO_URL5: any;
    EMAIL_IDS: any;
    MONTHLY_EVENT: any[];
    MONTHLY_MEETING: any[];
    MONTHLY_PROJECT: any[];
    ATTECHMENT_FILE: string;
}