export class EventsWorldCouncileOverview{
    ID:Number;
    GROUP_ID:number;
    TOTAL_EVENT:Number;
    EVENT_PLANNED:number;
    TOTAL_BENEFICIARIES:number;
    TOP_TYPES:number;

    EVENT_COUNT:number;
    PROJECT_NAME:any;

    EVENT_CONDUCTED:number;
    NO_OF_SHARES:number;
    AVG_LIKE_PER_MEMBER:number;
    TOTAL_COMMENT_TILL_DATE:number;
    AVG_COMMENT_PER_MEMBER:number;
    AVG_POST_PER_MEMBER:number;
    TOTAL_LIKE_TILL_DATE:number;
    TOTAL_POST:number;

    EVENT_LIKE_COUNT:number;
    EVENT_COMMENT_COUNT:number;
}