export class VicePresident {

    ID: number;
    MEMBER_ID :number;
    GROUP_ID  :number;
    DISCHARGED_DETAILS :string='';
    CONTRIBUTION_DETAILS :string='';
    FISCLE: string='';
    ACTIVITY_DETAILS :string='';
    INOVATIVATIVE_PROJECT_DETAILS:string='';
    TOTAL_GROUP_MEETINGS  :string= '';
    OUT_OF_MEETINGS  :string= '';
    TOTAL_BOARD_MEETINGS :string = '';
    BOARD_OUT_OF_MEETINGS :string = '';
    UNIT_COUNCILS_TOTAL :string = '';
    UNIT_COUNCILS_OUT_OF :string = '';
    UNIT_CONFERENCES_TOTAL :string= '';
    UNIT_CONFERENCES_OUT_OF :string= '';
    BRANCH_PROGRAM_COUNT :string= '';
    MEMBERSHIP_GROUP_GROWTH_DETAILS:string='';
    MORE_DETAILS  :string= '';
    ANNEXURE_DETAILS:string='';
    PHOTO_URL_1:string='';
    PHOTO_URL_2:string='';
    PHOTO_URL_3:string='';
    CREATED_DATE:any;
    CLIENT_ID:number=1;
    SWICTHING:Boolean=true;
    SWICTHING1:Boolean=true;
    SWICTHING2:Boolean=true;
    SWICTHING3:Boolean=true;
    IS_SUBMITED="D";
}
