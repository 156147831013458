export class Activities {
    ID: number;
    PERSON:string='';
    GROUP_ID  :number;
    MEMBER_ID:number;
    PROJECT_COUNT: any;
    DATE_OF_REPORT_TO_FEDERATION  :any;
    DATE_OF_REPORT_TO_BRANCH  :any;
    PHOTO_DETAILS1 :string='';
    PHOTO_URL1:string='';
    PHOTO_DETAILS2 :string='';
    PHOTO_URL2:string='';
    PHOTO_DETAILS3 :string='';
    PHOTO_URL3:string='';
    PHOTO_DETAILS4 :string='';
    PHOTO_URL4:string='';
    PHOTO_DETAILS5 :string='';
    PHOTO_URL5:string='';
    PHOTO_DETAILS6 :string='';
    PHOTO_URL6:string='';
    IS_GROUP_AWARDED: any=true;
    AWARD_DETAILS :string = '';
    OTHER_DETAILS:string='';
    CREATED_DATE:any;
    ACTIVITY_PROJECT_DETAILS:any[];
    CLIENT_ID:number=1;
    IS_SUBMITED='D'
}
