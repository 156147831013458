export class GroupMaster {
    ID: number;
    CLIENT_ID: number;
    UNIT_ID: number;
    NAME: string;
    STATUS: boolean = true;
    TYPE: string;
    BO_DATE: string;
    _20_21_MEMBER_COUNT: any = 0;
    _21_22_MEMBER_COUNT: any = 0;
    PRESIDENT: number;
    VPI: number;
    VPE: number;
    SECRETORY: number;
    TREASURER: number;
    DIRECTOR1: number;
    DIRECTOR2: number;
    DIRECTOR3: number;
    DIRECTOR4: number;
    DIRECTOR5: number;
    IPP: number;
    SHORT_NAME: string;
    SPONSERED_GROUP: any;
    GROUP_STATUS: string = "C";
    ORIENTATION_DATE: string;
    VENUE: string;
    ORIENTATION_DRAFT: string;
    GROUP_TYPE: string;
    REMARKS: string;
    MEMBER_REMARK: string;
    PAYMENT_REMARK: string;
    BOD_REMARK: string;
    ORIENTATION_LETTER_URL: any;
    PARENT_GROUP_ID: any;
    AD_ACC_NO: string;
    AD_BANK_NAME: string;
    AD_BRANCH_NAME: string;
    PR_ACC_NO: string;
    PR_BANK_NAME: string;
    PR_BRANCH_NAME: string;
    PAN_NUMBER: string;
    PAN_URL: string;
    DROPPED_STATUS: boolean = false;
    DROPPED_DATE: string;
    REVIVED_STATUS: boolean = false;
    REVIVED_DATE: string;
    REVIVED_BY_GROUP_ID: string = "0";
}