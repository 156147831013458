export class ActivityProjectDetails {
    ID: number;
    OUTSTANDING_ACTIVITY_ID: number;
    PROJECT_TITLE: string;
    IMPLEMENTATION_YEAR: string;
    CATEGORY: string;
    REGULARITY_DETAILS: string;
    BENEFITS_TO_COMMUNITY: string;
    EXPENSES_DETAILS: string;
    SPONSERSHIP_DETAILS: string;
    SPONSER_CERTIFICATE: any;
    PROJECT_PLANNING: string;
    EXECUTION_DETAILS: string;
    IMPACT_SOCIETY: string;
}
