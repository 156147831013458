export class UndertakenProjectDetails {
    ID: number;
    OUTSTANDING_GROUP_MASTER_ID: number;
    NAME: string;
    DATE_OF_IMPLEMENTATION: any;
    PROJECT_COST: number
    BENIFITED_PERSONS_COUNT: number;
    MEMBERS_COUNT: number;
    IMPACT_ON_SOCIETY: any;
    PHOTO_URL1: any;
    PHOTO_URL2: any;
    PHOTO_URL3: any;

    DETAILS:any;
}