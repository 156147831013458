import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allothercomponents',
  templateUrl: './allothercomponents.component.html',
  styleUrls: ['./allothercomponents.component.css']
})

export class AllothercomponentsComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
