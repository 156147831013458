export class BestServiceProjectMaster{
    ID:number;
    CLIENT_ID:number;
    GROUP_ID:number;
    GROUP_NAME:string;
    PROJECT_NAME:string;
    DATE_OF_IMPLEMENTATION:any;
    BENEFICIAERIES:string;
    INVOLVEMENT:number;
    NO_OF_NON_MEMBER:number;
    OTHER_ANGENCIES:number;
    REPORTING_DATE:any;
    TO_OTHER_ANGENCIES:string;
    TOTAL_EXPENCES_IN_PROJECT:number;
    PLANNING:string;
    EXECUTION:string;
    IMPACT_ON_SOCIETY:string;
    OTHER_INFORMATION:string;
    SERVICE_PROJECT_SPONSERED_DETAILS:any[];
    UNIT_CONFERENCE_AWARD_DETAILS:any[];

    IS_SUBMITED:string = 'D'
}