export class OutstandingGroupMaster {
    ID: number;
    CLIENT_ID: number;
    GROUP_ID: number;
    GROUP_NAME: string;
    DATE_OF_BIDING: any;
    GROUP_MEETINGS: number;
    AVG_ATTENDANCE_MEETINGS: number;
    MONTHLY_REPORTING_SENT: number;
    BOARD_MEETINGS: number;
    BOARD_MEETINGS_ATTENDENCE: number;
    OUTSTANDING_DUES_AMOUNT: number = 0;
    OUTSTANDING_DUES_DETAILS: any = '';
    FUND_RAISING_COLLECTED_AMOUNT: number;
    FUND_RAISING_GAINED_AMOUNT: number;
    MEMBERSHIP_ON_31_MARCH: number;
    MEMBERSHIP_ON_30_SEPT: number;
    MEMBERSHIP_ON_31_DEC: number;
    ATTENDANCE_OF_UNIT_COUNCILS_TOTAL: number;
    ATTENDANCE_OF_UNIT_COUNCILS_COUNT: number;
    ATTENDANCE_OF_UNIT_CONFERENCES_TOTAL: number;
    ATTENDANCE_OF_UNIT_CONFERENCES_COUNT: number;
    ATTENDANCE_OF_PAST_GWF_TOTAL: number;
    ATTENDANCE_OF_PAST_GWF_COUNT: number;
    MEMBER_MAILING_LIST_SENT_PRESENT_YEAR: any;
    BOD_MAILING_LIST_SENT_PRESENT_YEAR: any;
    MEMBER_MAILING_LIST_SENT_ENSUING_YEAR: any;
    BOD_MAILING_LIST_SENT_ENSUING_YEAR: any;
    OTHER_DETAILS: any;
    UNDER_PROJECT_DETAILS: any[];
    MEDIA_COVERAGE: any[];
    DUES_PAID_TO_F: any[];
    C_PROJECT_DETAILS: any[];
    SPONSERED_GROUP: any[];
    SWICTHING: Boolean = true;
    TYPE: any;
    IS_SUBMITED: string = 'D';
    AWARD_TYPE: string;
}