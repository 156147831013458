import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { FederationMaster } from 'src/app/Models/FederationMaster';
import { GroupMaster } from 'src/app/Models/GroupMaster';
import { UnitMaster } from 'src/app/Models/UnitMaster';
import { ApiService } from 'src/app/Service/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-group-bod-send-for-approval',
  templateUrl: './group-bod-send-for-approval.component.html',
  styleUrls: ['./group-bod-send-for-approval.component.css']
})

export class GroupBodSendForApprovalComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() drawerVisible: boolean;
  @Input() DOCUMENTS_LIST: any;
  @Input() PAYMENT_LIST: any;
  @Input() BIO_DATA_LIST: any;
  @Input() GROUP_INFO: GroupMaster;
  @Input() GROUP_INFO_FOR_DRAWER: any;
  @Input() NEW_GROUP_BOD: any;
  loadingRecords: boolean = false;
  federationID = Number(this._cookie.get("FEDERATION_ID"));
  unitID = Number(this._cookie.get("UNIT_ID"));
  groupID = Number(this._cookie.get("GROUP_ID"));
  isSpinning: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 0;
  federationMemberLoading: boolean = false;
  unitMemberLoading: boolean = false;
  groupMemberLoading: boolean = false;
  centralSpecialCommitteeMemberLoading: boolean = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '25rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };

  SUBJECT: string;
  DESCRIPTION: string;
  REMARK: string;
  FEDERATION_ID: any[] = [];
  NEW_GROUP_BOD_ID: any[] = [];
  FEDERATION_CENTRAL_SPECIAL_COMMITTEE: any[] = [];
  UNIT_ID: any[] = [];
  GROUP_ID: any[] = [];
  federationSelect: boolean = false;
  centralSpecialCommittee: boolean = false;
  unitSelect: boolean = false;
  groupSelect: boolean = false;
  newGroupBODSelect: boolean = false;
  chairPersonID: number;
  deputyPersonID: number;
  adminPersonID: number;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit() { }

  onComponentInitialized(): void {
    this.getAdminData();
    this.getFederations();
    this.getUnits();
    this.getGroups();
  }

  getAdminData() {
    this.api.getAllMembers(0, 0, 'NAME', 'asc', ' AND ID=(SELECT EMPLOYEE_ID from view_employee_role_mapping where ROLE_ID=60)').subscribe(data => {
      if ((data['code'] == 200) && (data["data"].length > 0)) {
        this.chairPersonID = data['data'][0]["ID"];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });

    this.api.getAllMembers(0, 0, 'NAME', 'asc', ' AND ID=(SELECT EMPLOYEE_ID from view_employee_role_mapping where ROLE_ID=61)').subscribe(data => {
      if ((data['code'] == 200) && (data["data"].length > 0)) {
        this.deputyPersonID = data['data'][0]["ID"];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });

    this.api.getAllMembers(0, 0, 'NAME', 'asc', ' AND ID=(SELECT EMPLOYEE_ID from view_employee_role_mapping where ROLE_ID=59)').subscribe(data => {
      if ((data['code'] == 200) && (data["data"].length > 0)) {
        this.adminPersonID = data['data'][0]["ID"];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  close(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  federations = [];

  getFederations() {
    this.federations = [];

    this.api.getAllFederations(0, 0, "NAME", "asc", " AND STATUS=1").subscribe(data => {
      if (data['code'] == 200) {
        this.federations = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  units = [];

  getUnits() {
    this.units = [];

    this.api.getAllUnits(0, 0, "NAME", "asc", " AND STATUS=1").subscribe(data => {
      if (data['code'] == 200) {
        this.units = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  groups = [];

  getGroups() {
    this.groups = [];

    this.api.getAllGroups(0, 0, "NAME", "asc", "").subscribe(data => {
      if (data['code'] == 200) {
        this.groups = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  BOD: string = "";
  memberList = [];
  federationmemberList = [];

  // get Federation BOD Members
  getFederationMemberData(federationID: number) {
    this.BOD = "";
    this.federationmemberList = [];

    let tempFederation = this.federations.filter(obj1 => {
      return obj1["ID"] == federationID;
    });

    let federationData: FederationMaster = tempFederation[0];

    this.BOD += federationData.PRESIDENT ? federationData.PRESIDENT + "," : "";
    this.BOD += federationData.IPP ? federationData.IPP + "," : "";
    this.BOD += federationData.VP1 ? federationData.VP1 + "," : "";
    this.BOD += federationData.VP2 ? federationData.VP2 + "," : "";
    this.BOD += federationData.VP3 ? federationData.VP3 + "," : "";
    this.BOD += federationData.SECRETORY ? federationData.SECRETORY + "," : "";
    this.BOD += federationData.CO_SECRETORY ? federationData.CO_SECRETORY + "," : "";
    this.BOD += federationData.TREASURER ? federationData.TREASURER + "," : "";
    this.BOD += federationData.PRO1 ? federationData.PRO1 + "," : "";
    this.BOD += federationData.PRO2 ? federationData.PRO2 + "," : "";
    this.BOD += federationData.CO_ORDINATOR ? federationData.CO_ORDINATOR + "," : "";
    this.BOD += federationData.SPECIAL_OFFICER1 ? federationData.SPECIAL_OFFICER1 + "," : "";
    this.BOD += federationData.SPECIAL_OFFICER2 ? federationData.SPECIAL_OFFICER2 + "," : "";
    this.BOD += federationData.SPECIAL_OFFICER3 ? federationData.SPECIAL_OFFICER3 + "," : "";
    this.BOD += federationData.SPECIAL_OFFICER4 ? federationData.SPECIAL_OFFICER4 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER1 ? federationData.FEDERATION_OFFICER1 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER2 ? federationData.FEDERATION_OFFICER2 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER3 ? federationData.FEDERATION_OFFICER3 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER4 ? federationData.FEDERATION_OFFICER4 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER5 ? federationData.FEDERATION_OFFICER5 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER6 ? federationData.FEDERATION_OFFICER6 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER7 ? federationData.FEDERATION_OFFICER7 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER8 ? federationData.FEDERATION_OFFICER8 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER9 ? federationData.FEDERATION_OFFICER9 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER10 ? federationData.FEDERATION_OFFICER10 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER11 ? federationData.FEDERATION_OFFICER11 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER12 ? federationData.FEDERATION_OFFICER12 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER13 ? federationData.FEDERATION_OFFICER13 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER14 ? federationData.FEDERATION_OFFICER14 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER15 ? federationData.FEDERATION_OFFICER15 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER16 ? federationData.FEDERATION_OFFICER16 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER17 ? federationData.FEDERATION_OFFICER17 + "," : "";
    this.BOD += federationData.CO_ORDINATOR2 ? federationData.CO_ORDINATOR2 + "," : "";
    this.BOD += federationData.FEDERATION_OFFICER18 ? federationData.FEDERATION_OFFICER18 + "," : "";

    if (federationData.PRESIDENT && (federationData.PRESIDENT.toString()).trim() != "") {
      this.federationmemberList.push(federationData.PRESIDENT ? federationData.PRESIDENT : '');
    }

    if (federationData.SECRETORY && (federationData.SECRETORY.toString()).trim() != "") {
      this.federationmemberList.push(federationData.SECRETORY ? federationData.SECRETORY : '');
    }

    if (this.BOD.length > 0) {
      this.BOD = this.BOD.substring(0, this.BOD.length - 1);
      this.federationMemberLoading = true;

      this.api.getAllMembers(0, 0, "", "", " AND ID IN (" + this.BOD + ")").subscribe(data => {
        if (data['code'] == 200) {
          this.federationMemberLoading = false;
          this.memberList = data['data'];
          this.FEDERATION_ID = this.federationmemberList;
        }

      }, err => {
        this.federationMemberLoading = false;

        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  selectFederationsMember(status: boolean) {
    let tempFederationsMembers = [];

    if (status) {
      this.memberList.map(obj1 => {
        tempFederationsMembers.push(obj1["ID"]);
      });

      this.FEDERATION_ID = tempFederationsMembers;

    } else {
      this.FEDERATION_ID = [];
      this.FEDERATION_ID = this.federationmemberList;
    }
  }

  centralSpecialCommitteeBOD: string = "";
  centralSpecialCommitteeMemberList = [];
  centralSpecialCommitteeFederationmemberList = [];

  // get Federation's Special and Central Committee
  getFederationcentralSpecialCommitteeMemberData(federationID: number) {
    this.centralSpecialCommitteeBOD = "";
    this.centralSpecialCommitteeFederationmemberList = [];

    let tempFederation = this.federations.filter(obj1 => {
      return obj1["ID"] == federationID;
    });

    let federationData: FederationMaster = tempFederation[0];

    this.centralSpecialCommitteeBOD += federationData.CENTRAL_COMMITTEE1 ? federationData.CENTRAL_COMMITTEE1 + "," : "";
    this.centralSpecialCommitteeBOD += federationData.CENTRAL_COMMITTEE2 ? federationData.CENTRAL_COMMITTEE2 + "," : "";
    this.centralSpecialCommitteeBOD += federationData.CENTRAL_COMMITTEE3 ? federationData.CENTRAL_COMMITTEE3 + "," : "";
    this.centralSpecialCommitteeBOD += federationData.SPECIAL_COMMITTEE1 ? federationData.SPECIAL_COMMITTEE1 + "," : "";
    this.centralSpecialCommitteeBOD += federationData.SPECIAL_COMMITTEE2 ? federationData.SPECIAL_COMMITTEE2 + "," : "";
    this.centralSpecialCommitteeBOD += federationData.SPECIAL_COMMITTEE3 ? federationData.SPECIAL_COMMITTEE3 + "," : "";

    this.centralSpecialCommitteeBOD += this.chairPersonID ? this.chairPersonID + "," : "";
    this.centralSpecialCommitteeBOD += this.deputyPersonID ? this.deputyPersonID + "," : "";
    this.centralSpecialCommitteeBOD += this.adminPersonID ? this.adminPersonID + "," : "";

    if (federationData.CENTRAL_COMMITTEE1 && (federationData.CENTRAL_COMMITTEE1.toString()).trim() != "") {
      this.centralSpecialCommitteeFederationmemberList.push(federationData.CENTRAL_COMMITTEE1 ? federationData.CENTRAL_COMMITTEE1 : '');
    }

    if (federationData.CENTRAL_COMMITTEE2 && (federationData.CENTRAL_COMMITTEE2.toString()).trim() != "") {
      this.centralSpecialCommitteeFederationmemberList.push(federationData.CENTRAL_COMMITTEE2 ? federationData.CENTRAL_COMMITTEE2 : '');
    }

    if (federationData.CENTRAL_COMMITTEE3 && (federationData.CENTRAL_COMMITTEE3.toString()).trim() != "") {
      this.centralSpecialCommitteeFederationmemberList.push(federationData.CENTRAL_COMMITTEE3 ? federationData.CENTRAL_COMMITTEE3 : '');
    }

    if (federationData.SPECIAL_COMMITTEE1 && (federationData.SPECIAL_COMMITTEE1.toString()).trim() != "") {
      this.centralSpecialCommitteeFederationmemberList.push(federationData.SPECIAL_COMMITTEE1 ? federationData.SPECIAL_COMMITTEE1 : '');
    }

    if (federationData.SPECIAL_COMMITTEE2 && (federationData.SPECIAL_COMMITTEE2.toString()).trim() != "") {
      this.centralSpecialCommitteeFederationmemberList.push(federationData.SPECIAL_COMMITTEE2 ? federationData.SPECIAL_COMMITTEE2 : '');
    }

    if (federationData.SPECIAL_COMMITTEE3 && (federationData.SPECIAL_COMMITTEE3.toString()).trim() != "") {
      this.centralSpecialCommitteeFederationmemberList.push(federationData.SPECIAL_COMMITTEE3 ? federationData.SPECIAL_COMMITTEE3 : '');
    }

    if (this.centralSpecialCommitteeBOD.length > 0) {
      this.centralSpecialCommitteeBOD = this.centralSpecialCommitteeBOD.substring(0, this.centralSpecialCommitteeBOD.length - 1);
      this.centralSpecialCommitteeMemberLoading = true;

      this.api.getAllMembers(0, 0, "", "", " AND ID IN (" + this.centralSpecialCommitteeBOD + ")").subscribe(data => {
        if (data['code'] == 200) {
          this.centralSpecialCommitteeMemberLoading = false;
          this.centralSpecialCommitteeMemberList = data['data'];
          this.FEDERATION_CENTRAL_SPECIAL_COMMITTEE = this.centralSpecialCommitteeFederationmemberList;
        }

      }, err => {
        this.centralSpecialCommitteeMemberLoading = false;

        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  selectFederationsCentralSpecialCommitteeMember(status: boolean) {
    let tempFederationsCentralSpecialCommitteeMembers = [];

    if (status) {
      this.centralSpecialCommitteeMemberList.map(obj1 => {
        tempFederationsCentralSpecialCommitteeMembers.push(obj1["ID"]);
      });

      this.FEDERATION_CENTRAL_SPECIAL_COMMITTEE = tempFederationsCentralSpecialCommitteeMembers;

    } else {
      this.FEDERATION_CENTRAL_SPECIAL_COMMITTEE = [];
      this.FEDERATION_CENTRAL_SPECIAL_COMMITTEE = this.centralSpecialCommitteeFederationmemberList;
    }
  }

  unitBOD: string = "";
  unitBODMemberList = [];
  unitMemberList = [];

  // get Unit BOD Members
  getUnitMemberData(unitID: number) {
    this.unitBOD = "";
    this.unitMemberList = [];

    let tempUnit = this.units.filter(obj1 => {
      return obj1["ID"] == unitID;
    });

    let unitData: UnitMaster = tempUnit[0];

    this.unitBOD += unitData.DIRECTOR ? unitData.DIRECTOR + "," : "";
    this.unitBOD += unitData.OFFICER1 ? unitData.OFFICER1 + "," : "";
    this.unitBOD += unitData.OFFICER2 ? unitData.OFFICER2 + "," : "";
    this.unitBOD += unitData.VP ? unitData.VP + "," : "";

    if (unitData.DIRECTOR && (unitData.DIRECTOR.toString()).trim() != "") {
      this.unitMemberList.push(unitData.DIRECTOR ? unitData.DIRECTOR : '');
    }

    if (this.unitBOD.length > 0) {
      this.unitBOD = this.unitBOD.substring(0, this.unitBOD.length - 1);
      this.unitMemberLoading = true;

      this.api.getAllMembers(0, 0, "", "", " AND ID IN (" + this.unitBOD + ")").subscribe(data => {
        if (data['code'] == 200) {
          this.unitMemberLoading = false;
          this.unitBODMemberList = data['data'];
          this.UNIT_ID = this.unitMemberList;
        }

      }, err => {
        this.unitMemberLoading = false;

        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  selectUnitsMember(status: boolean) {
    let tempUnitsMembers = [];

    if (status) {
      this.unitBODMemberList.map(obj1 => {
        tempUnitsMembers.push(obj1["ID"]);
      });

      this.UNIT_ID = tempUnitsMembers;

    } else {
      this.UNIT_ID = [];
      this.UNIT_ID = this.unitMemberList;
    }
  }

  sponseredGroupBOD: string = "";
  sponseredGroupBODMemberList = [];
  sponseredGroupMemberList = [];
  groupBOD: string = "";
  groupBODMemberList = [];
  groupMemberList = [];

  // get Group's Current BOD and new BOD Members
  getSponseredGroupMemberData(groupID: number) {
    this.groupBOD = "";
    this.groupMemberList = [];

    let tempGroup = this.groups.filter(obj1 => {
      return obj1["ID"] == groupID;
    });

    let groupBOD: GroupMaster = tempGroup[0];

    if (tempGroup.length > 0) {
      this.groupBOD += groupBOD.PRESIDENT ? groupBOD.PRESIDENT + "," : "";
      this.groupBOD += groupBOD.VPI ? groupBOD.VPI + "," : "";
      this.groupBOD += groupBOD.VPE ? groupBOD.VPE + "," : "";
      this.groupBOD += groupBOD.SECRETORY ? groupBOD.SECRETORY + "," : "";
      this.groupBOD += groupBOD.TREASURER ? groupBOD.TREASURER + "," : "";
      this.groupBOD += groupBOD.DIRECTOR1 ? groupBOD.DIRECTOR1 + "," : "";
      this.groupBOD += groupBOD.DIRECTOR2 ? groupBOD.DIRECTOR2 + "," : "";
      this.groupBOD += groupBOD.DIRECTOR3 ? groupBOD.DIRECTOR3 + "," : "";
      this.groupBOD += groupBOD.DIRECTOR4 ? groupBOD.DIRECTOR4 + "," : "";
      this.groupBOD += groupBOD.DIRECTOR5 ? groupBOD.DIRECTOR5 + "," : "";
      this.groupBOD += groupBOD.IPP ? groupBOD.IPP + "," : "";

      if (groupBOD.PRESIDENT && (groupBOD.PRESIDENT.toString()).trim() != "") {
        this.groupMemberList.push(groupBOD.PRESIDENT ? groupBOD.PRESIDENT : '');
      }
    }

    if (this.groupBOD.length > 0) {
      this.groupBOD = this.groupBOD.substring(0, this.groupBOD.length - 1);
      this.groupMemberLoading = true;

      this.api.getAllMembers(0, 0, "", "", " AND ID IN (" + this.groupBOD + ")").subscribe(data => {
        if (data['code'] == 200) {
          this.groupMemberLoading = false;
          this.sponseredGroupBODMemberList = data['data'];
          this.GROUP_ID = this.groupMemberList;
        }

      }, err => {
        this.groupMemberLoading = false;

        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  selectGroupsMember(status: boolean) {
    let tempGroupsMembers = [];

    if (status) {
      this.sponseredGroupBODMemberList.map(obj1 => {
        tempGroupsMembers.push(obj1["ID"]);
      });

      this.GROUP_ID = tempGroupsMembers;

    } else {
      this.GROUP_ID = [];
      this.GROUP_ID = this.groupMemberList;
    }
  }

  selectNewGroupsBODMember(status: boolean) {
    let tempGroupNewBODMembers = [];

    if (status) {
      this.NEW_GROUP_BOD.map(obj1 => {
        tempGroupNewBODMembers.push(obj1["ID"]);
      });

      this.NEW_GROUP_BOD_ID = tempGroupNewBODMembers;

    } else {
      this.NEW_GROUP_BOD_ID = [];
    }
  }

  onApproveBtnClick(myForm: NgForm, status: string): void {
    let isOk = true;

    if ((this.SUBJECT != undefined) || (this.SUBJECT != null)) {
      if (this.SUBJECT.trim() == "") {
        isOk = false;
        this.message.info("Please Enter Valid Subject", "");
      }

    } else {
      isOk = false;
      this.message.info("Please Enter Valid Subject", "");
    }

    if ((this.DESCRIPTION != undefined) || (this.DESCRIPTION != null)) {
      if (this.DESCRIPTION.trim() == "") {
        isOk = false;
        this.message.info("Please Enter Valid Description", "");
      }

    } else {
      isOk = false;
      this.message.info("Please Enter Valid Description", "");
    }

    if ((this.FEDERATION_ID.length == 0) && (this.UNIT_ID.length == 0) && (this.GROUP_ID.length == 0) && (this.NEW_GROUP_BOD_ID.length == 0) && (this.FEDERATION_CENTRAL_SPECIAL_COMMITTEE.length == 0)) {
      isOk = false;
      this.message.info("Please Select at Least One member to send Email", "");
    }

    if (isOk) {
      this.isSpinning = true;
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear();

      if (currentMonth <= 3) {
        currentYear = (currentYear - 1) + 1;

      } else {
        currentYear = currentYear;
      }

      let obj1 = new Object();
      obj1["SUBJECT"] = this.SUBJECT;
      obj1["MAIL_BODY"] = this.DESCRIPTION;
      obj1["FEDERATION_BOD"] = this.FEDERATION_ID;
      obj1["UNIT_BOD"] = this.UNIT_ID;
      obj1["GROUP_BOD"] = this.GROUP_ID;
      obj1["GROUP_NEW_BOD"] = this.NEW_GROUP_BOD_ID;
      obj1["COMMITTEE_BOD"] = this.FEDERATION_CENTRAL_SPECIAL_COMMITTEE;
      obj1["GROUP_ID"] = this.GROUP_INFO["GROUP_ID"];
      obj1["REMARK"] = this.REMARK;
      obj1["NEW_MEMBER_LIST"] = this.NEW_GROUP_BOD;
      obj1["YEAR"] = currentYear;
      obj1["APPROVER_ID"] = this.api.userId;
      obj1["STATUS"] = status;

      this.api.groupBODApproval(obj1).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.isSpinning = false;
          this.message.success("New BOD Approval Email Sent Successfully", "");
          this.close(myForm);

        } else {
          this.isSpinning = false;
          this.message.error("Failed to Send New BOD Approval Email", "");
        }
      });
    }
  }

  onRejectionBtnClick(myForm: NgForm, status: string) {
    let isOk = true;

    if ((this.SUBJECT != undefined) || (this.SUBJECT != null)) {
      if (this.SUBJECT.trim() == "") {
        isOk = false;
        this.message.info("Please Enter Valid Subject", "");
      }

    } else {
      isOk = false;
      this.message.info("Please Enter Valid Subject", "");
    }

    if ((this.DESCRIPTION != undefined) || (this.DESCRIPTION != null)) {
      if (this.DESCRIPTION.trim() == "") {
        isOk = false;
        this.message.info("Please Enter Valid Description", "");
      }

    } else {
      isOk = false;
      this.message.info("Please Enter Valid Description", "");
    }

    if ((this.REMARK != undefined) || (this.REMARK != null)) {
      if (this.REMARK.trim() == "") {
        isOk = false;
        this.message.info("Please Enter Valid Rejection Remark", "");
      }

    } else {
      isOk = false;
      this.message.info("Please Enter Valid Rejection Remark", "");
    }

    if ((this.FEDERATION_ID.length == 0) && (this.UNIT_ID.length == 0) && (this.GROUP_ID.length == 0) && (this.NEW_GROUP_BOD_ID.length == 0) && (this.FEDERATION_CENTRAL_SPECIAL_COMMITTEE.length == 0)) {
      isOk = false;
      this.message.info("Please Select at Least One member to send Email", "");
    }

    if (isOk) {
      this.isSpinning = true;
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear();

      if (currentMonth <= 3) {
        currentYear = (currentYear - 1) + 1;

      } else {
        currentYear = currentYear;
      }

      let obj1 = new Object();
      obj1["SUBJECT"] = this.SUBJECT;
      obj1["MAIL_BODY"] = this.DESCRIPTION;
      obj1["FEDERATION_BOD"] = this.FEDERATION_ID;
      obj1["UNIT_BOD"] = this.UNIT_ID;
      obj1["GROUP_BOD"] = this.GROUP_ID;
      obj1["GROUP_NEW_BOD"] = this.NEW_GROUP_BOD_ID;
      obj1["COMMITTEE_BOD"] = this.FEDERATION_CENTRAL_SPECIAL_COMMITTEE;
      obj1["GROUP_ID"] = this.GROUP_INFO["GROUP_ID"];
      obj1["REMARK"] = this.REMARK;
      obj1["NEW_MEMBER_LIST"] = this.NEW_GROUP_BOD;
      obj1["YEAR"] = currentYear;
      obj1["APPROVER_ID"] = this.api.userId;
      obj1["STATUS"] = status;

      this.api.groupBODApproval(obj1).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.isSpinning = false;
          this.message.success("New BOD Rejection Email Send Successfully", "");
          this.close(myForm);

        } else {
          this.isSpinning = false;
          this.message.error("Failed to Send New BOD Rejection Email", "");
        }
      });
    }
  }

  showBODList(url: string) {
    window.open(this.api.retriveimgUrl + "groupBODList/" + url);
  }

  showMailingList(url: string) {
    window.open(this.api.retriveimgUrl + "groupBODMailingList/" + url);
  }

  cancel() { }

  getNewBODRole(index: number) {
    if (index == 0) {
      return "President";
    }

    if (index == 1) {
      return "VPI";
    }

    if (index == 2) {
      return "VPE";
    }

    if (index == 3) {
      return "Secretary";
    }

    if (index == 4) {
      return "Treasurer";
    }

    if (index == 5) {
      return "IPP";
    }
  }
}
