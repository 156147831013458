export class GroupMapInvitees {
    ID: number;
    CLIENT_ID: number;
    GROUP_ID: any = [];
    UNIT_ID: any = [];
    ROLE_ID: any = [];
    CREATOR_ID: number;
    UNIT_NAME: string;
    UNITS: any;
    GROUPS: any;
}