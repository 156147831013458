export class FederationMaster {
    ID: number;
    CLIENT_ID: number;
    NAME: string;
    STATUS: boolean = true;
    PRESIDENT: number;
    IPP: number;
    VP1: number;
    VP2: number;
    VP3: number;
    SECRETORY: number;
    CO_SECRETORY: number;
    TREASURER: number;
    PRO1: number;
    PRO2: number;
    CO_ORDINATOR: number;
    SPECIAL_OFFICER1: number;
    SPECIAL_OFFICER2: number;
    SPECIAL_OFFICER3: number;
    SPECIAL_OFFICER4: number;
    SHORT_NAME: string;
    FEDERATION_OFFICER1: number;
    FEDERATION_OFFICER2: number;
    FEDERATION_OFFICER3: number;
    FEDERATION_OFFICER4: number;
    FEDERATION_OFFICER5: number;
    FEDERATION_OFFICER6: number;
    FEDERATION_OFFICER7: number;
    FEDERATION_OFFICER8: number;
    FEDERATION_OFFICER9: number;
    FEDERATION_OFFICER10: number;
    FEDERATION_OFFICER11: number;
    FEDERATION_OFFICER12: number;
    FEDERATION_OFFICER13: number;
    FEDERATION_OFFICER14: number;
    FEDERATION_OFFICER15: number;
    FEDERATION_OFFICER16: number;
    FEDERATION_OFFICER17: number;
    FEDERATION_OFFICER18: number;
    CENTRAL_COMMITTEE1: number;
    CENTRAL_COMMITTEE2: number;
    CENTRAL_COMMITTEE3: number;
    SPECIAL_COMMITTEE1: number;
    SPECIAL_COMMITTEE2: number;
    SPECIAL_COMMITTEE3: number;
    CO_ORDINATOR2: number;
}